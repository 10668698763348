import React from 'react';
import Footer from '../components/Footer';
import CSPetsIntro from '../components/CSPetsIntro';
import Contact from './Contact';

const CaseStudyPets = () => {
    return (
        <>
            <CSPetsIntro />
            <Contact />
        </>

    )
}

export default CaseStudyPets;


///<Clients />


