import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'; /* Translations */
import i18n from 'i18next';                     /* Translations - RTL handling */

import { Helmet } from 'react-helmet';          /* SEO for React */


import mongoDbImg from '../images/mongoImg.svg';
import ElasticsearchImg from '../images/ElasticsearchImg.svg';
import KubernetesImg from '../images/KubernetesImg.svg';
import KafkaImg from '../images/KafkaImg.svg';
import BigQueryImg from '../images/BigQueryImg.svg';
import SnowflakeImg from '../images/SnowflakeImg.svg';
import aiImage from '../images/dataImg.jpg'
import dataTechImage from '../images/dataTechImage.jpg'
import aiVideo1 from '../videos/cloud1.mp4'
import aiVideo2 from '../videos/cloud2.mp4'
import aiVideo3 from '../videos/cloud3.mp4'



const CloudInfo = () => {

    useDocTitle('CodeSuits | Cloud Development')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()


    const {

        tech_cloud_title,
        tech_cloud_subtitle,
        tech_cloud_description1,
        tech_cloud_description2,
        tech_cloud_solutions_title,
        tech_cloud_solutions_description,
        tech_cloud_ci_title,
        tech_cloud_ci_description,
        tech_cloud_cd_title,
        tech_cloud_cd_description,
        tech_cloud_delevelopment_title,
        tech_cloud_delevelopment_subtitle,
        tech_cloud_devops_title,
        tech_cloud_devops_subtitle,
        tech_cloud_talk,

    } = t("tech_pages.tech_cloud");

    useEffect(() => {

        window.scrollTo(0, 0);

        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);


    return (
        <>

            <link rel="canonical" href="https://codesuits.com/tech-cloud"></link>
            <Helmet>
                <title>CodeSuits | Cloud & DevOps</title>
                <meta
                    name='description'
                    content='Our Cloud experts will help to plan and impleent a cloud-powered solution for your business needs'
                />
                <meta
                    name='keywords'
                    content="data, database, artificial intelligence, ai, machine learning, ml, server, aws, gcp, azure, cloud, cloud-based solutions, cloud solutions, מידע, בינה מלאכותית, ענןֿ, דאטה, אחסון בענן"
                />
            </Helmet>

            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">

                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl ">{tech_cloud_title}</h2>
                    </div>

                    <div class="max-w-2xl mx-auto text-center">
                        <p class=" mx-auto mt-12 text-lg leading-relaxed text-gray-600"><b>{tech_cloud_subtitle} </b> </p>
                        <div class="mt-5">
                            <p mt-8 text-base text-gray-600 >  {tech_cloud_description1}</p>

                            <p className='mt-8 text-base '><b>{tech_cloud_description2} </b></p>
                        </div>
                    </div>
                </div>



                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-3  w-full gap-x-12 gap-y-6">


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo1} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cloud_solutions_title} </p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_cloud_solutions_description}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo2} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cloud_ci_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_cloud_ci_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo3} type="video/mp4" />
                                    </video>
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_cloud_cd_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_cloud_cd_description}</p>
                                </div>
                            </div>




                        </div>

                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_cloud_delevelopment_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cloud_delevelopment_subtitle}</p>
                                </div>


                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={dataTechImage} />
                                </div>

                            </div>

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center my-12">
                                    <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                        {tech_cloud_devops_title}</h4>

                                    <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_cloud_devops_subtitle} </p>
                                </div>

                                <div className="flex lg:justify-end w-full mt-16" data-aos="fade-up" data-aos-delay="700">
                                    <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={aiImage} />
                                </div>
                            </div>


                            {/* ---  UX Tech Stack --- */}

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left overflow-auto md:overflow-scroll w-full" data-aos="fade-up">


                                    <div className='flex justify-center items-center gap-8 mt-12' data-aos="zoom-in" data-aos-delay="500">

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={mongoDbImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                MongoDB</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={ElasticsearchImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Elasticsearch</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={KubernetesImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Kubernetes</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={KafkaImg} style={{ height: 42, width: 42 }} />
                                            </div>



                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Kafka</h3>
                                        </div>

                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={BigQueryImg} style={{ height: 42, width: 42 }} />
                                            </div>


                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                BigQry</h3>
                                        </div>


                                        <div className='flex-col justify-center items-center'>

                                            <div className='flex  justify-center items-center'>
                                                <img alt="" className="rounded-t float-center" src={SnowflakeImg} style={{ height: 42, width: 42 }} />
                                            </div>

                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                Snowflake</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/get-demo" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-900 transition-all duration-200 border-2 border-blue-900 rounded-md mt-9 hover:bg-blue-900 hover:text-white hover:border-blue-900 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_cloud_talk}
                                </Link>

                            </div>
                        </section>
                    </div>
                </div >

            </section>

            <Footer />
        </>
    )
}
export default CloudInfo;