import React, { useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


import img from '../images/tech_data.svg';
import img1 from '../images/industry-marketing.png';
import img2 from '../images/industry-retail.png';
import img3 from '../images/industry-finance.png';
import img4 from '../images/industry-autootive.png';
import img5 from '../images/healthImg.svg';




const Industries = () => {


    const { t } = useTranslation()
    const {title, description} = t("industries");
    const {marketing_title, marketing_description} = t("industries.marketing");
    const {retail_title, retail_description} = t("industries.retail");
    const {finance_title, finance_description} = t("industries.finance");
    const {auto_title, auto_description} = t("industries.automotive");
    const {share_title1, share_title2, share_description} = t("industries.share");
    const {agile_title1, agile_title2, agile_description} = t("industries.agile");

    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef();

    // Function to handle scrolling when the button is clicked
    const handleScroll = (scrollAmount) => {
        // ...
    };


    return (

        <div id="industries" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{description}</h2>
                </div>


                <div className="px-12" data-aos="fade-down" data-aos-delay="600">


                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <div  className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] transition scale-75  duration-1000 ease-in-out" src={img1} />
                                <h2 className="font-semibold my-4 text-2xl text-center">{marketing_title}</h2>
                                <p className="text-md font-medium">
                                {/* {marketing_description} */}
                                </p>
                            </div>
                        </div>

                        <div  className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] transition scale-75  duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">{retail_title}</h2>
                                <p className="text-md font-medium">
                                    {/* {retail_description} */}
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] transition scale-75  duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">{finance_title}</h2>
                                <p className="text-md font-medium">
                                    {/* {finance_description} */}
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">

                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] transition scale-75  duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">{auto_title}</h2>
                                <p className="text-md font-medium">
                                    {/* {auto_description} */}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {share_title1}<span className='font-black'> {share_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{share_description}</h2>
                        </div>
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {agile_title1}<span className='font-black'> {agile_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{agile_description}</h2>
                        </div>
                    </div>
                </div>
            </section>


            {/*
            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-10'>
                                


                            <svg width="86" height="86" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#233c85"></path> </g></svg>


                            </div>
                            <h3 className="text-3xl  text-blue-900 
                            font-bold">{share_title1} <span className='font-black'>{share_title2}</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                {share_description}
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4'>

                                
                                
                                <svg fill="#233c85"  width="72" height="72"  viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#233c85"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier">  <path d="M29.762 1.004h-14.443c0 0 0 0 0 0 0 3.599 2.918 6.517 6.517 6.517 0 0 0 0 0 0h2.66v2.571c0.003 3.591 2.91 6.502 6.498 6.512h0.001v-14.343c0-0.002 0-0.003 0-0.005 0-0.685-0.55-1.241-1.232-1.251h-0.001zM22.616 8.198h-14.443c0.001 3.599 2.918 6.516 6.517 6.516h2.66v2.572c0.003 3.598 2.919 6.513 6.517 6.516h0v-14.352c0-0.691-0.56-1.251-1.251-1.251v0zM15.464 15.391h-14.46c0.002 3.6 2.921 6.517 6.521 6.517 0.006 0 0.012 0 0.018-0h2.661v2.57c0 0 0 0 0 0 0 3.598 2.916 6.515 6.514 6.517h0v-14.348c0-0.694-0.562-1.256-1.256-1.256v0z"></path> </g></svg>

 


                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">{agile_title1} <span className='font-black'>{agile_title2}</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{agile_description}.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            */}

        </div>
    )



}

export default Industries;