import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */


import NavBar from '../components/Navbar/NavBar';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom'
import squidLogo from '../images/squid-192.svg';
import squidImg1 from '../images/casestudy/squidImg1.jpg';
import squidImg2 from '../images/casestudy/squidImg2.jpg';
import wearLogo from '../images/casestudy/wewear-logo.jpg';

// websites
import printLogo from '../images/casestudy/printLogo.png';
import printBg from '../images/casestudy/printBg.png';

import foodilyLogo from '../images/casestudy/foodily-log.jpg';
import foodilyBg from '../images/casestudy/foodily.jpg';

import pureLogo from '../images/casestudy/pure-logo.jpg';
import pureBg from '../images/casestudy/pureBg.jpg';

import roveroLogo from '../images/casestudy/rovero-logo.jpg';
import roveroBg from '../images/casestudy/roveroBg.jpg';

import adelLogo from '../images/casestudy/adellogo.jpg';
import adelBg from '../images/casestudy/adelBg.jpg';

import kingLogo from '../images/casestudy/kingLogo.jpg';
import kingBg from '../images/casestudy/kingBg.jpg';


import parkLogo from '../images/casestudy/parkLogo.jpg';
import parkBg from '../images/casestudy/parkBg.jpg';


import lanasLogo from '../images/casestudy/lanasLogo.jpg';
import lanasBg from '../images/casestudy/lanasBg.jpg';

import trachtLogo from '../images/casestudy/trachtLogo.jpg';
import trachBg from '../images/casestudy/trachtBg.jpg';




import shukLogo from '../images/casestudy/shukLogo.png';
import shukBg from '../images/casestudy/shukBg.jpg';

import petsLogo from '../images/casestudy/petsLogo.png';
import petsBg from '../images/casestudy/petsBg.jpg';



// saas
import squidBg from '../images/casestudy/squid_bg.png';

import qspaceBg from '../images/casestudy/qspaceBg.jpg';
import qspaceLogo from '../images/casestudy/qspaceLogo.jpg';

import saaslyBg from '../images/casestudy/saaslyBg.jpg';
import saaslyLogo from '../images/casestudy/saaslyLogo.jpg';

import ipsumBg from '../images/casestudy/ipsumBg.jpg';
import ipsumLogo from '../images/casestudy/ipsumLogo.jpg';

// mobile
import grabLogo from '../images/casestudy/grabLogo.png';
import grabBG from '../images/casestudy/grabBg.jpg';

import healthLogo from '../images/casestudy/health-logo.jpg';
import healthBG from '../images/casestudy/healthBg.png';

import ridesLogo from '../images/casestudy/ridesLogo.jpg';
import ridesBG from '../images/casestudy/ridesBg.jpg';

import netpayLogo from '../images/casestudy/netpayLogo.jpg';
import netpayBg from '../images/casestudy/netpayBg.png';
import wewearBg from '../images/casestudy/wewearBg.png';

import icoWeb from '../images/casestudy/ico_web.png';
import icoSaaS from '../images/casestudy/icoSaaS.png';
import icoMobile from '../images/casestudy/icoMobile.png';




import portfolioIntro from '../images/casestudy/portfolio-top.png';


const PortfolioPage = () => {

    const { t } = useTranslation()
    const { title, subtitle } = t("portfolio_all");

    const { web_title } = t("portfolio_all.websites");


    const {
        adel_title,
        adel_subtitle,
        adel_description,
        adel_learn,

        rovero_title,
        rovero_subtitle,
        rovero_description,
        rovero_learn,

        relax_title,
        relax_subtitle,
        relax_description,
        relax_learn,

        foodily_title,
        foodily_subtitle,
        foodily_description,
        foodily_learn,

        solomon_title,
        solomon_subtitle,
        solomon_description,
        solomon_learn,

        park_title,
        park_subtitle,
        park_description,
        park_learn,

        lena_title,
        lena_subtitle,
        lena_description,
        lena_learn,

        tracht_title,
        tracht_subtitle,
        tracht_description,
        tracht_learn,

        // wewear_title,
        // wewear_subtitle,
        // wewear_description,
        // wewear_learn,


    } = t("portfolio_all.websites");


    const { print_title, print_subtitle, print_description, print_learn } = t("portfolio_all.websites");
    const { bfood_title, bfood_subtitle, bfood_description, bfood_learn } = t("portfolio_all.websites");
    const { pets_title, pets_subtitle, pets_description, pets_learn } = t("portfolio_all.websites");



    const { saas_title, } = t("portfolio_all.saas");
    const { squid_title, squid_subtitle, squid_description, squid_learn } = t("portfolio_all.saas");
    const { qspace_title, qspace_subtitle, qspace_description, qspace_learn } = t("portfolio_all.saas");
    const { saasly_title, saasly_subtitle, saasly_description, saasly_learn } = t("portfolio_all.saas");
    const { ipsum_title, ipsum_subtitle, ipsum_description, ipsum_learn } = t("portfolio_all.saas");



    const { mobile_title, } = t("portfolio_all.mobile");

    const { grab_title, grab_subtitle, grab_description, grab_learn } = t("portfolio_all.mobile");
    const { health_title, health_subtitle, health_description, health_learn } = t("portfolio_all.mobile");
    const { rides_title, rides_subtitle, rides_description, rides_learn } = t("portfolio_all.mobile");
    const { netpay_title, netpay_subtitle, netpay_description, netpay_learn } = t("portfolio_all.mobile");
    const { goal_title, goal1, goal2, goal3, goal4 } = t("portfolio_all.pages.goals");



    const { design_title, } = t("portfolio_all.design");
    const { ux_title, ux_description, ux_learn } = t("portfolio_all.design");
    const { ui_title, ui_description, ui_learn } = t("portfolio_all.design");
    const { media_title, media_description, media_learn } = t("portfolio_all.design");
    const { docs_title, docs_description, docs_learn } = t("portfolio_all.design");





    const { wewear_title, wewear_subtitle, wewear_description, wewear_learn } = t("portfolio.wewear");

    useEffect(() => {

        window.scrollTo(0, 0);
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);



    return (

        <>
            <div className="PortfolioPage" id='portfolio-all'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>




                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='portfolio' >

                    {/* lg:text-left */}

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between" data-aos="fade-up">

                        {/* text-center lg:text-left */}

                        <div className="flex-col my-4  lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {title}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                            </h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{subtitle}</p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-18 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={portfolioIntro} />
                        </div>

                    </div>
                </div>

                {/* ---  Websites  ---*/}


                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    {/* ---  row of icon & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-gray-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Twitter">

                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoWeb} />


                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900 pr-2">
                                        {web_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>



                    {/* ---  row items 1 ---*/}


                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${foodilyBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={foodilyLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{foodily_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{foodily_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {foodily_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-foodily" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {foodily_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>


                                        </Link>
                                    </div>
                                </div>

                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${pureBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={pureLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{relax_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{relax_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {relax_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-relax" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {relax_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>

                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${roveroBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={roveroLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{rovero_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{rovero_subtitle} </h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {rovero_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-rovero" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {rovero_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${adelBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={adelLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{adel_title} </h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{adel_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {adel_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-adel" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {adel_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    {/* ---  row items 2 ---*/}

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>


                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${kingBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={kingLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{solomon_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{solomon_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {solomon_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-king" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {solomon_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>

                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${parkBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={parkLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{park_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{park_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {park_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-park" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {park_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${lanasBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={lanasLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{lena_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{lena_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {lena_description}  </p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-lanas" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {lena_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${trachBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={trachtLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{tracht_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{tracht_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {tracht_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-tracht" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {tracht_learn}
                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* ---  row items 3 ---*/}

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>



                    <div className="px-4" data-aos="fade-down" data-aos-delay="600" >
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${printBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={printLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{print_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{print_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {print_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-print" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {print_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>

                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${shukBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={shukLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{bfood_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{bfood_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {bfood_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-bari" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {bfood_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${wewearBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={wearLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{wewear_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{wewear_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {wewear_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-wewear" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {wewear_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${petsBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={petsLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{pets_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{pets_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {pets_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-pets" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {pets_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>


                </div>



                {/* ---  Web and SaaS  ---*/}




                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#FFFFFF' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>





                    {/* ---  row of icone & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900  hover:text-gray-500 bg-white hover:bg-gray-200 shadow transition duration-150 ease-in-out" aria-label="Twitter">


                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoSaaS} />



                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900 pr-2 ">
                                        {saas_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>






                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${squidBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={squidLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{squid_title} </h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{squid_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {squid_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-squid" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {squid_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>

                                        </Link>
                                    </div>
                                </div>
                            </div>


                            {/* style={{ stroke:"black" , strokeWidth: "10px" }} */}

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${qspaceBg})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={qspaceLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{qspace_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{qspace_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {qspace_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-quickspace" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {qspace_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${saaslyBg})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={saaslyLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{saasly_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{saasly_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {saasly_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-saasly" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {saasly_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${ipsumBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={ipsumLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{ipsum_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{ipsum_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {ipsum_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-logo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {ipsum_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>


                {/* ---  Mobile  apps  ---*/}


                <div className="w-full m-auto  p-2 md:p-12 h-5/6" id='casestudy_mobile' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    </div>




                    {/* ---  row of icone & title   ---*/}
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <div className="mx-auto text-center mt-2">
                            <ul className="flex justify-left mb-4 md:mb-0">
                                <li>
                                    <div className="flex justify-center items-center text-blue-900  hover:text-gray-500 bg-gray-100 hover:bg-gray-200 shadow transition duration-150 ease-in-out" aria-label="Twitter">

                                        <img alt="card img" className=" rounded-t float-right duration-1000 w-full" style={{ height: 38, width: 38 }} src={icoMobile} />

                                    </div>
                                </li>
                                <li className="ml-4">

                                    <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900 pr-2">
                                        {mobile_title}</h1>
                                </li>
                            </ul>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>





                    <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${grabBG})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center" >
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={grabLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{grab_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{grab_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {grab_description}
                                    </p>



                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-grab" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {grab_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>

                                        </Link>
                                    </div>
                                </div>
                            </div>


                            {/* style={{ stroke:"black" , strokeWidth: "10px" }} */}

                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${healthBG})` }}  >
                                <div className="m-2 text-justify text-sm" >
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full " style={{ height: 56, width: 56 }} src={healthLogo} />
                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{health_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{health_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        {health_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-healthness" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {health_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>



                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${ridesBG})` }} >
                                <div className="m-2 text-justify text-sm">
                                    <div className="grid align-items:center">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={ridesLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{rides_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{rides_subtitle}</h4>
                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">

                                        <p> {rides_description}</p>
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-rides" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {rides_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>

                                        </Link>
                                    </div>
                                </div>
                            </div>


                            <div className="border-2 border-blue-800 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max" style={{ backgroundImage: `url(${netpayBg})` }}>
                                <div className="m-2 text-justify text-sm ">
                                    <div className="grid align-items:center ">
                                        <img alt="card img" className="border-2 border-gray-300 rounded-t float-right duration-1000 w-full" style={{ height: 56, width: 56 }} src={netpayLogo} />

                                        <h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-1">{netpay_title}</h4>
                                    </div>

                                    <h4 className="font-semibold my-4 text-base md:text-base text-center mb-4 h-12">{netpay_subtitle}</h4>

                                    <p className="text-base font-medium leading-5 h-auto md:h-48 flex justify-center">
                                        {netpay_description}
                                    </p>
                                    <div className="flex justify-center my-4">
                                        <Link to="/casestudy-fintech" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                            {netpay_learn}

                                            <div> {i18n.language == 'en' ?
                                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                :
                                                <svg className="w-8 h-4 ml-2 pl-2 scale-x-[-1] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            }</div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between" data-aos="fade-up">


                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg2} />
                        </div>


                        {/* text-center lg:text-left */}

                        <div className="flex-col my-4  lg:my-0 lg:justify-end w-full lg:w-1/2 px-8 mb-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {goal_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{goal4}</li>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ---  Contatct & Footer  ---*/}

                <div>
                    <Contact></Contact>
                </div>


            </div >
        </>
    )

}

export default PortfolioPage;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

