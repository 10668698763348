import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import axios from 'axios';
import Notiflix from 'notiflix';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Collapsible from 'react-collapsible';
import Contact from '../pages/Contact';
import i18n from 'i18next';

import aboutImg from '../images/aboutImg.jpg'
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './About.css';
import PropTypes from "prop-types";
import { ReactComponent as Logo } from "../icons/expand-vertical.svg";


const data = [
    {
        id: 1,
        open: true,
        question: "What is Frontend Mentor, and how will it help me?",
        answer:
            "Frontend Mentor offers realistic coding challenges to help developers improve their frontend coding skills with projects in HTML, CSS, and JavaScript. It's suitable for    all levels and ideal for portfolio building.",
    },
    {
        id: 2,
        open: false,
        question: "Is Frontend Mentor free?",
        answer:
            "Yes, Frontend Mentor offers both free and premium coding challenges, with the free option providing access to a range of projects suitable for all skill levels.",
    },
    {
        id: 3,
        open: false,
        question: "Can I use Frontend Mentor projects in my portfolio?",
        answer:
            "Yes, you can use projects completed on Frontend Mentor in your portfolio. It's an excellent way to showcase your skills to potential employers!",
    },
    {
        id: 4,
        open: false,
        question: "How can I get help if I'm stuck on a Frontend Mentor challenge?",
        answer:
            "The best place to get help is inside Frontend Mentor's Discord community. There's a help channel where you can ask questions and seek support from other community members.",
    },
];



const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: false,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const swiperOptions2 = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: false,
    autoplay: {
        delay: 500,
        disableOnInteraction: true,
        reverseDirection: true,
    },
    loop: true,

    breakpoints: {
        320: {
            slidesPerView: 1,
            // spaceBetween: 30, 225
        },
        575: {
            slidesPerView: 1,
            // spaceBetween: 30, 162
        },
        767: {
            slidesPerView: 2,
            // spaceBetween: 30, 
        },
        991: {
            slidesPerView: 3,
            // spaceBetween: 30,
        },
        1199: {
            slidesPerView: 4,
            // spaceBetween: 30,
        },
        1350: {
            slidesPerView: 6,
            // spaceBetween: 30,
        },
    }


};

const FAQ = () => {
    //    useDocTitle('CodeSuits | Data Solutions')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const { t } = useTranslation()



    const {

        faq_title,
        faq_subtitle,

        faq_general_title,
        faq_general_q1,
        faq_general_a1,
        faq_general_q2,
        faq_general_a2,
        faq_general_q3,
        faq_general_a3,
        faq_general_q4,
        faq_general_a4,

        faq_pricing_title,
        faq_pricing_q1,
        faq_pricing_a1,
        faq_pricing_q2,
        faq_pricing_a2,
        faq_pricing_q3,
        faq_pricing_a3,
        faq_pricing_q4,
        faq_pricing_a4,

        faq_support_title,
        faq_support_q1,
        faq_support_a1,
        faq_support_q2,
        faq_support_a2,
        faq_support_q3,
        faq_support_a3,
        faq_support_q4,
        faq_support_a4,


    } = t("company_pages.faq");



    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);



    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });

    }

    return (
        <>

            <Helmet>
                <title>CodeSuits | FAQ</title>
                <meta
                    name='description'
                    content='Our Data experts will help plan and impleent for your business data-based and ml/ai solutions'
                />
                <meta
                    name='keywords'
                    content="data, database, artificial intelligence, ai, machine learning, ml, server, aws, gcp, azure, cloud, cloud-based solutions, cloud solutions, מידע, בינה מלאכותית, ענןֿ, דאטה, אחסון בענן"
                />
            </Helmet>



            <div>
                <NavBar />
            </div>

            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                </div>

                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8  md:px-1 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-lg">
                        <section class="pt-10 pb-8 overflow-hidden sm:pt-16 lg:pt-24">

                            <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">

                                <div class="max-w-2xl mx-auto text-center">
                                    <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl">{faq_title}</h2>
                                </div>


                                <div class="max-w-2xl mx-auto text-center mb-12">

                                    {/* <p class="max-w-xl mx-auto mt-12 text-lg leading-relaxed text-gray-600">{about_subtitle}</p> */}
                                </div>

                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl">
                                <div class=" py-12 ">


                                    {/* General Section */}

                                    <div class="overflow-hidden bg-white rounded-2xl drop-shadow-2xl ">
                                        <div class="px-12 py-8 pb-16">

                                            {/* text-left */}
                                            <div class=" ">
                                                <h2 class="text-lg font-bold  text-blue-900 sm:text-2xl lg:text-3xl">{faq_general_title}</h2>
                                            </div>


                                            <div className='font-bold pt-12'>
                                                <Collapsible className='lg:text-xl' trigger={faq_general_q1}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_general_a1}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_general_q2}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_general_a2}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_general_q3}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_general_a3}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_general_q4}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_general_a4}
                                                    </p>

                                                </Collapsible>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Pricing Section */}


                                    <div class="overflow-hidden bg-white rounded-2xl drop-shadow-2xl mt-12">
                                        <div class="px-12 py-8 pb-16">

                                            <div class=" ">
                                                <h2 class="text-lg font-bold  text-blue-900 sm:text-2xl lg:text-3xl">{faq_pricing_title}</h2>
                                            </div>


                                            <div className='font-bold pt-12'>
                                                <Collapsible className='lg:text-xl' trigger={faq_pricing_q1}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_pricing_a1}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_pricing_q2}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_pricing_a2}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_pricing_q3}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_pricing_a3}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_pricing_q4}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_pricing_a4}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Support Section */}

                                    <div class="overflow-hidden bg-white rounded-2xl drop-shadow-2xl mt-12">
                                        <div class="px-12 py-8 pb-16">

                                            <div class=" ">
                                                <h2 class="text-lg font-bold  text-blue-900 sm:text-2xl lg:text-3xl">{faq_support_title}</h2>
                                            </div>


                                            <div className='font-bold pt-12'>
                                                <Collapsible className='lg:text-xl' trigger={faq_support_q1}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_support_a1}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_support_q2}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_support_a2}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_support_q3}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_support_a3}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>

                                            <div className='font-bold pt-6'>
                                                <Collapsible className='lg:text-xl' trigger={faq_support_q4}>
                                                    <p className='font-normal pt-4'>
                                                        {faq_support_a4}
                                                    </p>
                                                    {/* <p>
                                                        It can even be another Collapsible component. Check out the next
                                                        section!
                                                    </p> */}
                                                </Collapsible>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </section>



                    </div>
                </div >



            </section>



            <Contact />



            {/* <Footer /> */}
        </>


    )
}

export default FAQ;