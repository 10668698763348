import React from 'react';
import Footer from '../components/Footer';
import CSRidesIntro from '../components/CSRidesIntro';
import Contact from './Contact';

const CaseStudyRides = () => {
    return (
        <>
            <CSRidesIntro />
            <Contact />
        </>

    )
}

export default CaseStudyRides;


///<Clients />


