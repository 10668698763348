import React from 'react';
import Footer from '../components/Footer';
import CSUXIntro from '../components/CSUXIntro';
import Contact from './Contact';

const CaseStudyUX = () => {
    return (
        <>
            <CSUXIntro />
            <Contact />
        </>

    )
}

export default CaseStudyUX;


///<Clients />


