import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

import webIcon from '../images/Internet.svg';
import nativeIcon from '../images/mobileNativeIcon.svg';
import crossIcon from '../images/mobileCross.svg';
import sdkIcon from '../images/mobileSdk.svg';
import hollisticsIcon from '../images/mobileHolistic.svg';


import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import wpsLogo from '../images/casestudy/wpsLogo.svg';
import wooLogo from '../images/casestudy/wooLogo.svg';
import joomLogo from '../images/casestudy/joomLogo.svg';




const MobileDevelopment = () => {

//    useDocTitle('CodeSuits | Mobile Development')

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation()
    const { mobile_title,
        mobile_subtitle1,
        mobile_subtitle2,
        mobile_subtitle3,
        contact
    } = t("services_pages.mobile");

    const { native_title,
        native_description,
    } = t("services_pages.mobile.native");

    const { cross_title,
        cross_description1,
        cross_description2,
    } = t("services_pages.mobile.cross");

    const { sdk_title,
        sdk_description,
    } = t("services_pages.mobile.sdk");

    const { hollistic_title,
        hollistic_description,
    } = t("services_pages.mobile.hollistic");

    const { web_case_study } = t("services_pages.webdev");


    return (
        <>

            <link rel="canonical" href="https://codesuits.com/about"></link>
            <Helmet>
                <title>CodeSuits | Mobile Development</title>
                <meta
                    name='description'
                    content='We develop mobile applications for Android and iOS using native and cross-platform technologies'
                />
                <meta
                    name='keywords'
                    content="Software, Software Engineering, Web application, Mobile application, Android, iOS, Mobile app, CodeSuits, Coding, Software application, SW solutions, Software company"
                />
            </Helmet>

            <div>
                <NavBar />
            </div>

            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
                        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div class="text-center">
                                <div class="max-w-2xl mx-auto text-center">
                                    <h2 className="font-bold leading-tight text-center lg:text-center text-blue-900  text-4xl">{mobile_title}</h2>
                                    <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">{mobile_subtitle1}
                                    </p>
                                    {mobile_subtitle2}

                                    <p class="max-w-xl mx-auto text-base leading-relaxed text-gray-600">{mobile_subtitle3}
                                    </p>

                                </div>

                            </div>


                            <div class="px-5 py-8 mt-12 bg-white lg:mt-20 lg:p-16">
                                <div class="grid grid-cols-1 gap-12 lg:gap-16 sm:grid-cols-2">
                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={nativeIcon} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{native_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{native_description}
                                            </p>
                                        </div>
                                    </div>

                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={crossIcon} />

                                        {/*    
                                        <svg class="flex-shrink-0 w-16 h-16 text-fuchsia-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                        </svg> */}

                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{cross_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{cross_description1}</p>
                                            <div class="mt-4 text-base text-black">{cross_description2}</div>
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={sdkIcon} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{sdk_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{sdk_description}</p>
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={hollisticsIcon} />
                                        {/*                
                                        <svg class="flex-shrink-0 w-16 h-16 text-fuchsia-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                        </svg>
                                        */}
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">{hollistic_title}</h3>
                                            <p class="mt-4 text-base text-gray-600">{hollistic_description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className=" mx-20 my-8 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/portfolio-all/?:casestudy_mobile" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                {web_case_study}
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </section>

                </div>
            </div>

            <Footer />
        </>


    )
}

export default MobileDevelopment;