import React from 'react';
import Footer from '../components/Footer';
import CSTrachtIntro from '../components/CSTrachtIntro';
import Contact from './Contact';

const CaseStudyTracht = () => {
    return (
        <>
            <CSTrachtIntro />
            <Contact />
        </>

    )
}

export default CaseStudyTracht;


///<Clients />


