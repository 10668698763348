import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import axios from 'axios';
// import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';


import { useTranslation } from 'react-i18next';

import webIcon from '../images/Internet.svg';
import nativeIcon from '../images/mobileNativeIcon.svg';
import crossIcon from '../images/mobileCross.svg';
import sdkIcon from '../images/mobileSdk.svg';
import hollisticsIcon from '../images/mobileHolistic.svg';

import startupImg from '../images/startup.svg';
import archiImg from '../images/architecture.svg';
import dataImg from '../images/data.svg';


const ITConsultantInfo = () => {



    useDocTitle('CodeSuits | IT Consultant')

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    const { t } = useTranslation()
    const { mobile_title,
        mobile_subtitle1,
        mobile_subtitle2,
        mobile_subtitle3,
        contact
    } = t("services_pages.mobile");

    const { native_title,
        native_description,
    } = t("services_pages.mobile.native");

    const { cross_title,
        cross_description1,
        cross_description2,
    } = t("services_pages.mobile.cross");

    const { sdk_title,
        sdk_description,
    } = t("services_pages.mobile.sdk");

    const { hollistic_title,
        hollistic_description,
    } = t("services_pages.mobile.hollistic");

    const { web_case_study } = t("services_pages.webdev");






    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);


    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = true;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const { response } = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }
    return (
        <>


            <Helmet>
                <title>CodeSuits | IT Consultant</title>
                <meta
                    name='description'
                    content='CodeSuits IT experts will help your team to plan or resolve technical aspects of your product'
                />
                <meta
                    name='keywords'
                    content="information technology, it , architecture design, software design, software solutions, פתרונות תוכנה, ארכיטקטורה"
                />
            </Helmet>


            <div>
                <NavBar />
            </div>



            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">


                    <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
                        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div class="text-center">
                                <div class="max-w-2xl mx-auto text-center">
                                    <h2 className="font-bold leading-tight text-center lg:text-center text-blue-900  text-4xl">IT Consultant</h2>

                                    <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">IT consulting at <b>CodeSuits</b> isn't just about technology.
                                    </p>We advice just how and what can be done to achieve higher business outcomes.
                                    <p class="max-w-xl mx-auto text-base leading-relaxed text-gray-600">
                                    </p>
                                </div>
                            </div>


                            <div class="px-5 py-8 mt-12 bg-white lg:mt-20 lg:p-16">
                                <div class="grid grid-cols-1 gap-12 lg:gap-16 sm:grid-cols-2">
                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={startupImg} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">Tech consultant for Startups & Enterprises</h3>
                                            <p class="mt-4 text-base text-gray-600"><b>CodeSuits</b> is expertise and flexible with supporting small startups and teams in large companies.
                                            </p>

                                            {/* <p class="mt-4 text-base text-gray-600">{native_description}
                                            </p> */}
                                        </div>
                                    </div>



                                    <div class="flex items-start">

                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={archiImg} />

                                        {/*    
                                        <svg class="flex-shrink-0 w-16 h-16 text-fuchsia-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                        </svg> */}

                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">E2E Architechture design and Developmment plan</h3>
                                            <p class="mt-4 text-base text-gray-600"> Our experts will advise and help your teams plan the end-to-end architecture design of your product.   </p>

                                            {/* <p class="mt-4 text-base text-gray-600">{cross_description1}</p>
                                            <div class="mt-4 text-base text-black">{cross_description2}</div> */}
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={nativeIcon} />
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">Mobile and Web Apps Integration</h3>
                                            <p class="mt-4 text-base text-gray-600"> Our experts, with over 20 years of development in the mobile and web industry, will help your business meet its goals.  </p>
                                            {/* <p class="mt-4 text-base text-gray-600">{sdk_description}</p> */}
                                        </div>
                                    </div>

                                    <div class="flex items-start">
                                        <img alt="card img" className="rounded-t w-16 h-16 float-right" src={dataImg} />
                                        {/*                
                                        <svg class="flex-shrink-0 w-16 h-16 text-fuchsia-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                        </svg>
                                        */}
                                        <div class="ml-5">
                                            <h3 class="text-lg font-semibold text-black">Cloud-based, Data and AI Development</h3>
                                            {/* <p class="mt-4 text-base text-gray-600">Security & user's privacy concerns</p> */}
                                            <p class="mt-4 text-base text-gray-600">CodeSuits experts, experienced in cloud-based projects, big data, ML and artificial intelligence.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className=" mx-20 my-8 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/portfolio-all/?:casestudy_mobile" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                {web_case_study}
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </section>

                </div>
            </div>


            {/* <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                        <div className="grid sm:grid-cols-2 py-1 md:py-2 lg:ml-1">
                            <div className="flex flex-col text-white">
                                <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 250, width: 300 }} src={itLogo} />
                            </div>
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">IT Consultant</h1>
                            </div>
                        </div>

                        <div class="scrollable-div">
                            <div className="text-xl font-semibold tracking-tight mb-5 text-gray-500">

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p>IT consulting at <b>CodeSuits</b> isn't just about technology.</p>
                                <p>We advice just how and what can be done to achieve higher business outcomes.</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <li class="Bullet1"><span><b>End-to-End Architechture design and developmment plan</b> </span></li>
                                <li class="Bullet1"><span><b>Mobile and Web apps integration</b> </span></li>
                                <li class="Bullet1"><span><b>Cloud-based developmment</b> </span></li>
                                <li class="Bullet1"><span><b>Security & user's privacy concerns</b> </span></li>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="my-2 w-1/2 lg:w-2/4">
                            <Link to="/contact" className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                Request a Consultant
                            </Link>
                        </div>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                    </div>

                    <div className="w-full  lg:-mt-96 lg:w-2/6 px-12 py-12 ml-auto bg-blue-900 rounded-2xl">
                        <div className="flex flex-col text-white">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" style={{ height: 200, width: 356.38 }} src={itImg} />
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>


    )
}

export default ITConsultantInfo;