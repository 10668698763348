import React from 'react';
import Footer from '../components/Footer';
import CSSquidIntro from '../components/CSSquidIntro';
import Contact from './Contact';

const CaseStudySquid = () => {
    return (
        <>
            <CSSquidIntro />
            <Contact />
        </>

    )
}

export default CaseStudySquid;


///<Clients />


