import React from 'react';
import Footer from '../components/Footer';
import CSFinTechIntro from '../components/CSFinTechIntro';

import Contact from './Contact';

const CaseStudyFinTech = () => {
    return (
        <>
            <CSFinTechIntro />
            <Contact />
        </>

    )
}

export default CaseStudyFinTech;
