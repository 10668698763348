import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from './Navbar/NavBar';
import Footer from './Footer';
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';


import squidCSImg from '../images/squid_demo.svg';
import squidProblemImg from '../images/consultation.svg';
import squidLogo from '../images/squid-192.svg';
import './CSHealthIntro.css'

import phpLogo from '../images/casestudy/phpLogo.svg';
import nodejsLogo from '../images/casestudy/nodejsLogo.svg';
import awsLogo from '../images/casestudy/awsLogo.svg';
import kotlinLogo from '../images/casestudy/kotlinLogo.svg';
import swiftLogo from '../images/casestudy/swiftLogo.svg';
import pythonLogo from '../images/casestudy/pythonLogo.svg';

import squidImg1 from '../images/casestudy/squidImg1.jpg';
import squidImg2 from '../images/casestudy/squidImg2.jpg';
import squidImg3 from '../images/casestudy/squidImg3.jpg';


const CSSquidIntro = () => {


    const { t } = useTranslation()
    const { squid_title, squid_subtitle1, squid_subtitle2 } = t("portfolio_all.pages.squid");


    const { squid_description,
        squid_client_title,
        squid_product_title,
        squid_work_title,
        squid_client_description,
        squid_product_description,
        squid_work_description } = t("portfolio_all.pages.squid");

    const { squid_profile,
        squid_profile_country_title,
        squid_profile_country_value,
        squid_profile_industry_titile,
        squid_profile_industry_value,
        squid_profile_team_titile,
        squid_profile_team_value } = t("portfolio_all.pages.squid");


    const { squid_goals,
        squid_goal1,
        squid_goal2,
        squid_goal3,
        squid_goal4 } = t("portfolio_all.pages.squid");


    const { squid_challanges,
        squid_challange_title1,
        squid_challange_1,
        squid_challange_title2,
        squid_challange_2,
        squid_challange_title3,
        squid_challange_3 } = t("portfolio_all.pages.squid");

    const { squid_solution_title,
        squid_solution_description1,
        squid_solution_description2,
        squid_solution_description3 } = t("portfolio_all.pages.squid");

    const { squid_techstack,
        squid_techstack1,
        squid_techstack2,
        squid_techstack3,
        squid_techstack4,
        squid_techstack5,
        squid_techstack6 } = t("portfolio_all.pages.squid");

    const { squid_results_title,
        squid_result_title1,
        squid_result_description1,
        squid_result_title2,
        squid_result_description2,
        squid_result_title3,
        squid_result_description3 } = t("portfolio_all.pages.squid");


        useEffect(() => {

            window.scrollTo(0, 0);
        }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>



                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {squid_title}</h1>


                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_subtitle2}</p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg1} />
                        </div>

                    </div>
                </div>



                {/* ---  DESCRIPTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_description}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_client_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_client_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_product_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_product_description}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_work_title}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_work_description}</p>
                            </div>
                        </div>
                    </div>


                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {squid_profile}</h1>
                        <p className='my-3 text-xl text-gray-600 font-regular'><b>{squid_profile_country_title}: </b> {squid_profile_country_value}</p> <p className='my-3 text-xl text-gray-600 font-regular'><b>{squid_profile_industry_titile}: </b> {squid_profile_industry_value}</p> <p className='my-3 text-xl text-gray-600 font-font-regular'><b>{squid_profile_team_titile}:</b> {squid_profile_team_value}</p>

                    </div>
                </div>



                {/* ---  GOALS  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">


                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg2} />
                        </div>



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_goals}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{squid_goal1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{squid_goal2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{squid_goal3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{squid_goal4}</li>
                            </div>
                        </div>
                   </div>
                </div>


                {/* ---  CHALLANGES  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' style={{ backgroundColor: '#F3F4F5' }}>
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_challanges}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_challange_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_challange_1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_challange_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_challange_2}</p>
                            </div>
                        </div>


                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_challange_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_challange_3}</p>
                            </div>
                        </div>

                    </div>

                </div>


                {/* ---  SOLUTION  ---*/}


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">



                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_solution_title}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>


                            <div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_solution_description1}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_solution_description2}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_solution_description3}</p>

                                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                </div>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={squidImg3} />
                        </div>

                    </div>


                    {/* ---  Tech Stack --- */}

                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                        <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                            {squid_techstack}</h1>
                        <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                            <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                <img alt="card img" className="rounded-t float-right" src={phpLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack1}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={nodejsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack2}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={awsLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack3}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={kotlinLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack4}</h3>
                                    </div>

                                </div>
                            </div>


                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={swiftLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack5}</h3>
                                    </div>

                                </div>
                            </div>

                            <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                alignItems: "center"
                            }}>
                                <img alt="card img" className="rounded-t float-right" src={pythonLogo} style={{ height: 42, width: 42 }} />

                                <div>
                                    <div >
                                        <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                            {squid_techstack6}</h3>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* ---  RESULT  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_results_title}</h1>
                        </div>
                    </div>

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_result_title1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_result_description1}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_result_title2}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_result_description2}</p>
                            </div>
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {squid_result_title3}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>{squid_result_description3}</p>
                            </div>
                        </div>

                    </div>

                </div>


            </div >
        </>
    )

}

export default CSSquidIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

