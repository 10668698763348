import { React, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import heroImg from '../images/intro-img.png';

import ModalVideo from 'react-modal-video'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';                     /* Translations - RTL handling */


//import heroVideo from '../videos/codedsuits-intro.mp4'
import './Hero.css'



const Hero = () => {

    const [isOpen, setOpen] = useState(false)

    //https://youtu.be/-79ahr9CrY0

    const { t } = useTranslation()

    useEffect(() => {

        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);

    return (
        <>

            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>


                <div className='bodyHero' >

                    <link rel='canonical' href='https://codesuits.com' />
                    <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in" >
                        <div className="video-box z-1">
                            <figure className="video-image">

                                <div id='hero' className="flex flex-col lg:flex-row py-20 pb-2  justify-between ">
                                    <div className="lg:w-1/2 flex flex-col" data-aos="zoom-in" data-aos-delay="200">
                                        <h1 className="mb-8 md:text-5xl text-3xl py-8 font-bold text-blue-900">
                                            {t("description")}
                                        </h1>
                                        <a onClick={() => setOpen(true)} className="lightbox-image overlay-box"><span className="flaticon-play-icon" ><i className="ripple"></i></span> <i className="icon fas fa-play p-60" aria-hidden="true"></i> </a>

                                        <div className="text-xl lg:w-3/4 font-semibold tracking-tight text-gray-500 mt-64">
                                            {t("subtitle")}
                                        </div>

                                        <div className="casestudy py-4 mb-4 space-x-0 md:space-x-2 md:mb-8 z-50">
                                            <Link to="/portfolio-all" className="text-white bg-blue-500 hover:bg-blue-700 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0" >
                                                {t("casestudy")}


                                                <div> {i18n.language == 'en' ?
                                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                    :
                                                    <svg className="w-8 h-4 ml-1 scale-x-[-1]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                                }</div>

                                            </Link>
                                        </div>
                                    </div>


                                    <div className="flex lg:justify-center  lg:w-1/2" data-aos="fade-up" data-aos-delay="700">
                                        <img alt="" className="content-center scale-75 rounded-t duration-1000" src={heroImg} />
                                    </div>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="Qn0Qcz-wJYI" onClose={() => setOpen(false)} />
        </>
    )
}

export default Hero;
