import React, { useEffect } from 'react';
import img from '../images/tech-ai.png';
import img2 from '../images/tech-cloud.png';
import img3 from '../images/tech-cross.png';
import img4 from '../images/tech-securitry.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



const Technologies = () => {


    const { t } = useTranslation()
    const { title, description } = t("tech");
    const { data_title, data_description } = t("tech.data");
    const { cloud_title, cloud_description } = t("tech.cloud");
    const { cross_title, cross_description } = t("tech.cross");
    const { security_title, security_description } = t("tech.security");
    const { creative_title1, creative_title2, creative_description } = t("tech.creative");
    const { innovative_title1, innovative_title2, innovative_description } = t("tech.innovative");


    return (


        <div id="technologies" className="bg-gray-100 py-12" >

            <link rel='canonical' href='https://codesuits.com/#technologies' />

            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">{title}</h2>

                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-blue-900'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">{description}</h2>
                </div>


                <div className="px-12" data-aos="fade-down" data-aos-delay="600">


                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                        <Link to="/tech-data" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] transition scale-75 duration-1000 ease-in-out" src={img} />
                                <h2 className="font-semibold my-4 text-2xl text-center">{data_title}</h2>
                                {/* <p className="text-md font-medium">
                                    {data_description}
                                </p> */}
                            </div>
                        </Link>

                        <Link to="/tech-cloud" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] scale-75 transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">{cloud_title}</h2>
                                {/* <p className="text-md font-medium">
                                    {cloud_description}
                                </p> */}
                            </div>
                        </Link>

                        <Link to="/tech-cross" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] scale-75 transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">{cross_title}</h2>
                                {/* <p className="text-md font-medium">
                                    {cross_description}
                                </p> */}
                            </div>
                        </Link>

                        <Link to="/tech-security" className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl pb-16 p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.0] scale-75 transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center ">{security_title}</h2>
                                {/* <p className="text-md font-medium">
                                    {security_description}
                                </p> */}
                            </div>
                        </Link>

                    </div>
                </div>
            </section>



            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {creative_title1}<span className='font-black'> {creative_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{creative_description}</h2>
                        </div>
                        <div className="my-4 py-4">
                            <h3 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold"> {innovative_title1}<span className='font-black'> {innovative_title2} </span></h3>
                            <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-regular text-blue-900">{innovative_description}</h2>
                        </div>
                    </div>
                </div>
            </section>

           

        </div>
    )
}
export default Technologies;
