import React, { useState, useEffect } from 'react';
import NavBar from './Navbar/NavBar';
import { useTranslation } from 'react-i18next';


import './CSHealthIntro.css'

import marketingImg1 from '../images/casestudy/marketingImg1.jpg';
import marketingImg2 from '../images/casestudy/marketingImg2.jpg';
import marketingImg3 from '../images/casestudy/marketingImg3.jpg';


const CaseStudyMarketingIntro = () => {

    const { t } = useTranslation()
    const { market_title, market_subtitle1 } = t("portfolio_all.pages.marketing");


    const { market_title2,
        market_bullet1,
        market_bullet2,
        market_bullet3,
        market_bullet4,
    } = t("portfolio_all.pages.marketing");

    const { market_title3,
        market_bullet5,
        market_bullet6,
        market_bullet7, } = t("portfolio_all.pages.marketing");


        useEffect(() => {

            window.scrollTo(0, 0);
        }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>



                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {market_title}</h1>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {market_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'></p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={marketingImg1} />
                        </div>
                    </div>
                </div>


                {/* ---  Maintaining  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={marketingImg2} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {market_title2}</h1>


                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet3}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet4}</li>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ---  User-centricity  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {market_title3}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                            <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet5}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet6}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{market_bullet7}</li>

                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={marketingImg3} />
                        </div>

                    </div>
                </div>

            </div >
        </>
    )

}

export default CaseStudyMarketingIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

