import React from 'react';
import Footer from '../components/Footer';
import CaseStudyFastGrabIntro from '../components/CaseStudyFastGrabIntro';
import Contact from './Contact';

const CaseStudyFastGrab = () => {
    return (
        <>
            <CaseStudyFastGrabIntro />
            <Contact />
        </>

    )
}

export default CaseStudyFastGrab;


///<Clients />


