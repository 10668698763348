import React from 'react';
import Footer from '../components/Footer';
import CSSaaslyIntro from '../components/CSSaaslyIntro';
import Contact from './Contact';

const CaseStudySaasly = () => {
    return (
        <>
            <CSSaaslyIntro />
            <Contact />
        </>

    )
}

export default CaseStudySaasly;


///<Clients />


