import React from 'react';
import Footer from '../components/Footer';
import CSHealthnessIntro from '../components/CSHealthIntro';
import Contact from './Contact';
import CSWeWearIntro from '../components/CSWeWearIntro';

const CaseStudyHealthness = () => {
    return (
        <>
            <CSWeWearIntro />
            <Contact />
        </>

    )
}

export default CaseStudyHealthness;
