import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

import accountImg from '../../images/icons/icon_account.png'


import './NavLinks.css'



const languages = [
    {
        code: 'he',
        name: 'עברית',
        dir: 'rtl',
        align: 'right',
        country_code: 'il',
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
        align: 'left',
        dir: 'ltr',
    },

]


const GlobeIcon = ({ width = 24, height = 24 }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="currentColor"
        className="bi bi-globe"
        viewBox="0 0 16 16"
    >
        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
)


const NavLinks = () => {

    const { t, i18n } = useTranslation();

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

    const { home, services, tech, industries, casestudy, company, company_about, company_process, company_faq, lang, schedule } = t("navbar");


    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        // setting to local storage
        localStorage.setItem("lang", languageValue);
    };

    const onClick = (e) => {
        console.log('click ', e);
    };

    const navigate = useNavigate();

    const onSubMenu = (e) => {

        const selection = e.target.value;

        console.log('e = ', e.target.value);

        if (selection == 'about') {
            navigate('/about');
        }
        if (selection == 'process') {
            navigate('/process');
        }
        else if (selection == 'faq') {
            navigate('/faq');
        }
        else {

        }
    };



    return (
        <>


            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#herovideo">
                {home}
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                {services}
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#technologies">
                {tech}
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#industries">
                {industries}
            </HashLink>

            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/portfolio-all">
                {casestudy}
            </HashLink>


            <select
                className="custom-select submenu px-4 font-extrabold text-gray-500 hover:text-blue-900 "
                style={{ align: 'center', paddingLeft: 8, background: 'transparent' }}
                onChange={onSubMenu}
            >
                <option selected color='#FF8B8'>{company}</option>
                <option value="about" color='#FF8B8'>{company_about}</option>
                <option value="process" color='#FF8B8'>{company_process}</option>
                <option value="faq" color='#FF8B8'>{company_faq}</option>

            </select>



            <select
                className="custom-select submenu px-2 font-normal text-gray-500 hover:text-blue-900"
                style={{ width: 92, align: 'center', paddingLeft: 10, background: 'transparent' }}
                onChange={changeLanguageHandler}

            >
                <option selected>{lang}</option>
                <option value="he">עברית</option>
                <option value="en">English</option>

            </select>


            <HashLink className="tooltip inline-flex items-center justify-center w-auto px-4" smooth target='_blank' to="https://app.codesuits.com">
                <img src={accountImg} style={{ width: 26, height: 26 }} data-tooltip-target='tooltip-default'></img>
            </HashLink>

            {/* <div id="tooltip-default" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                Customers login
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div> */}

            <Tooltip anchorSelect=".tooltip" place="top">
                Customers
            </Tooltip>


            {/* 4698FD */}

            <HashLink className="text-white bg-blue-500 hover:bg-blue-700 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
                {schedule}
            </HashLink>


        </>

    )
}

export default NavLinks;



