import React from 'react';
import Footer from '../components/Footer';
import CSFoodilyIntro from '../components/CSFoodilyIntro';
import Contact from './Contact';

const CaseStudyFoodily = () => {
    return (
        <>
            <CSFoodilyIntro />
            <Contact />
        </>

    )
}

export default CaseStudyFoodily;


///<Clients />


