import React, { useState, useEffect } from 'react';
import NavBar from './Navbar/NavBar';
import { useTranslation } from 'react-i18next';

import './CSHealthIntro.css'

import docsImg1 from '../images/casestudy/docsImg1.jpg';
import docsImg2 from '../images/casestudy/docsImg3.jpg';
import docsImg3 from '../images/casestudy/docsImg2.jpg';


import readmeLogo from '../images/casestudy/readmeLogo.svg';
import doxyLogo from '../images/casestudy/doxyLogo.svg';
import clickLogo from '../images/casestudy/clickLogo.svg';
import doc360Logo from '../images/casestudy/360Logo.svg';
import gitLogo from '../images/casestudy/gitLogo.svg';
import tettraLogo from '../images/casestudy/tettraLogo.svg';


const CSDocsIntro = () => {

    const { t } = useTranslation()
    const { dcos_title, dcos_subtitle1 } = t("portfolio_all.pages.dcos");

    const { dcos_title1,
        dcos_bullet1,
        dcos_bullet2,
        dcos_bullet3, } = t("portfolio_all.pages.dcos");

    const { dcos_title2,
        dcos_bullet4,
        dcos_bullet5,
        dcos_bullet6, } = t("portfolio_all.pages.dcos");

    const { dcos_title3,
        dcos_bullet7,
        dcos_bullet8, } = t("portfolio_all.pages.dcos");

    const { dcos_techstack,
        dcos_techstack1,
        dcos_techstack2,
        dcos_techstack3,
        dcos_techstack4,
        dcos_techstack5,
        dcos_techstack6,
    } = t("portfolio_all.pages.dcos");


    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    return (

        <>
            <div className="squid" id='squid'>
                <div>
                    <NavBar />
                </div>

                {/* ---  INTRO  ---*/}


                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>

                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                </div>


                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">
                                {dcos_title}</h1>

                            <h3 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {dcos_subtitle1}</h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'></p>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={docsImg1} />
                        </div>
                    </div>
                </div>


                {/* ---  Documentation  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={docsImg3} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {dcos_title1}</h1>



                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet1}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet2}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet3}</li>
                            </div>
                        </div>
                    </div>
                </div>



                {/* ---  Documentation  ---*/}

                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">


                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {dcos_title2}</h1>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet4}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet5}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet6}</li>
                            </div>
                        </div>

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={docsImg2} />
                        </div>

                    </div>
                </div>


                {/* ---  Blogs  ---*/}



                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                        <div className="lg:w-1/2 flex flex-col lg:mx-24 justify-center">
                            <img alt="card img" className="rounded-t float-right" src={docsImg3} />
                        </div>

                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">

                            <h1 className="mb-5 md:text-3xl text-3xl font-bold text-blue-900">
                                {dcos_title3}</h1>



                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>

                            <div>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet7}</li>
                                <li className='my-3 text-xl text-gray-600 font-semibold'>{dcos_bullet8}</li>
                            </div>
                        </div>
                    </div>



                    {/* ---  Tech Stack --- */}


                    <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                        {/* ---  Tech Stack --- */}





                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            <h1 className="mb-2 md:text-2xl text-3xl font-bold text-blue-900">
                                {dcos_techstack}</h1>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                            </div>
                            <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">

                                <div className="flex-col my-6 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                                    <img alt="card img" className="rounded-t float-right" src={readmeLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack1}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={doxyLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack2}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={clickLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack3}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={doc360Logo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack4}</h3>
                                        </div>

                                    </div>
                                </div>


                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={gitLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack5}</h3>
                                        </div>

                                    </div>
                                </div>

                                <div className="flex-col my-6 text-center lg:text-center lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500" style={{
                                    alignItems: "center"
                                }}>
                                    <img alt="card img" className="rounded-t float-right" src={tettraLogo} style={{ height: 42, width: 42 }} />

                                    <div>
                                        <div >
                                            <h3 className="my-3 text-xl text-gray-600 font-semibold"  >
                                                {dcos_techstack6}</h3>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )

}

export default CSDocsIntro;




// teleporting  https://play.teleporthq.io/projects/portfolio-page-bmoc3s/editor/9fa467fc-86c5-4001-96ba-81fb58f06cb4

