import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { Link } from 'react-router-dom'
import flutterImg from '../images/flutter.png';
import reactImg from '../images/reactnative.png'
import xamarinImg from '../images/xamarin.png'

import obfscImg from '../images/security_obfuscation.jpg'
import encImg from '../images/security_encryption.jpg'
import rootImg from '../images/security_rooted.jpg'
import mitmImg from '../images/security_mitm.jpg'
import malwareImg from '../images/security_malware.jpg'
import ransomImg from '../images/security_ ransomware.jpg'
import dataImg from '../images/security_data.jpg'
import cloudImg from '../images/security_cloud.jpg'
import apiImg from '../images/security_api.jpg'
import iotImg from '../images/security_iot.jpg'
import ddosImg from '../images/security_ddos.jpg'
import zeroImg from '../images/security_zero.jpg'


import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const SecurityInfo = () => {

    useDocTitle('CodeSuits | Security & Privacy')
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const dir = i18n.dir(i18n.language);
        document.documentElement.dir = dir;
    }, [i18n, i18n.language]);

    const { t } = useTranslation()


    const {


        tech_security_title,
        tech_security_subtitle1,
        tech_security_subtitle2,

        tech_security_mobile_title,
        tech_security_mobile_subtitle1,
        tech_security_mobile_subtitle2,

        tech_security_mobile_obfuscation_title,
        tech_security_mobile_obfuscation_description,

        tech_security_mobile_encryption_title,
        tech_security_mobile_encryption_description,

        tech_security_mobile_jailbreak_title,
        tech_security_mobile_jailbreak_description,

        tech_security_mobile_mitm_title,
        tech_security_mobile_mitm_description,

        tech_security_cloud_title,
        tech_security_cloud_subtitle,

        tech_security_cloud_malware_title,
        tech_security_cloud_malware_description,

        tech_security_cloud_ransomware_title,
        tech_security_cloud_ransomware_description,

        tech_security_cloud_data_title,
        tech_security_cloud_data_description,

        tech_security_cloud_vuln_title,
        tech_security_cloud_vuln_description,

        tech_security_cloud_api_title,
        tech_security_cloud_api_description,

        tech_security_cloud_iot_title,
        tech_security_cloud_iot_description,

        tech_security_cloud_ddos_title,
        tech_security_cloud_ddos_description,

        tech_security_cloud_zero_title,
        tech_security_cloud_zero_description,

        tech_security_talk,


    } = t("tech_pages.tech_security");




    return (
        <>
            <div>
                <NavBar />
            </div>


            <section class="py-10 bg-gray-100 sm:py-16 lg:py-24  ">


                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 my-24"  >
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold leading-tight text-gray-800 sm:text-4xl lg:text-5xl ">{tech_security_title}</h2>
                    </div>

                    <div class=" mx-auto text-center">
                        <p class=" mx-auto mt-12 text-xl leading-relaxed text-gray-600"> {tech_security_subtitle1}</p>
                    </div>
                    <div class="max-w-2xl mx-auto text-center">
                        <p class="max-w-xl mx-auto  text-xl leading-relaxed text-gray-600">{tech_security_subtitle2}</p>
                    </div>
                </div>

                <div className="container mx-auto  px-4 lg:px-20" data-aos="zoom-in">
                    <div className="w-full bg-white p-8  md:px-12 lg:w-12/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">


                        <div class="max-w-2xl mx-auto text-center sm:mt-12 lg:mt-20">
                            <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                {tech_security_mobile_title}</h4>

                            <p class="mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_security_mobile_subtitle1} </p>
                            <p class="mx-auto text-lg leading-relaxed text-gray-600">{tech_security_mobile_subtitle2}</p>
                        </div>

                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-4  w-full gap-x-12 gap-y-6 mb-12">

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">


                                    <img class="flex justify-center mx-auto " src={obfscImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_mobile_obfuscation_title} </p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_security_mobile_obfuscation_description}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={encImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_mobile_encryption_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_mobile_encryption_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={rootImg} alt="" />
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_mobile_jailbreak_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_mobile_jailbreak_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={mitmImg} alt="" />

                                    {/* <video autoPlay loop muted className="flex justify-center mx-auto">
                                        <source src={aiVideo3} type="video/mp4" />
                                    </video> */}
                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_mobile_mitm_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_mobile_mitm_description}</p>
                                </div>
                            </div>
                        </div>


                        <div class="max-w-2xl mx-auto text-center sm:mt-12 lg:mt-20">
                            <h4 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-4xl">
                                {tech_security_cloud_title}</h4>

                            <p class="mx-auto mt-12 text-lg leading-relaxed text-gray-600">{tech_security_cloud_subtitle}</p>
                        </div>



                        <div class="grid max-w-xl grid-cols-1 mx-auto mt-8 text-center lg:max-w-full  sm:mt-12 lg:mt-20 lg:grid-cols-4  w-full gap-x-12 gap-y-6 mb-24">

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">


                                    <img class="flex justify-center mx-auto " src={malwareImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_malware_title} </p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_security_cloud_malware_description}</p>
                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={ransomImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_ransomware_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_cloud_ransomware_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={dataImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_data_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_cloud_data_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={cloudImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_vuln_title}</p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_security_cloud_vuln_description}</p>
                                </div>
                            </div>



                            {/* 2nd line */}

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl ">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={apiImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_api_title}</p>
                                    <p class="mt-1 text-base text-gray-600"> {tech_security_cloud_api_description}</p>

                                </div>
                            </div>


                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={iotImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_iot_title}</p>
                                    <p class="mt-1 text-base tech_security_cloud_subtitletext-gray-600">{tech_security_cloud_iot_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={ddosImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_ddos_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_cloud_ddos_description}</p>
                                </div>
                            </div>

                            <div class="overflow-hidden bg-white rounded-2xl shadow-2xl">
                                <div class="px-8 py-12">

                                    <img class="flex justify-center mx-auto " src={zeroImg} alt="" />

                                    <p class="text-base font-semibold tex-tblack mt-9">{tech_security_cloud_zero_title}</p>
                                    <p class="mt-1 text-base text-gray-600">{tech_security_cloud_zero_description}</p>
                                </div>
                            </div>

                            <div class="max-w-2xl mx-auto text-center lg:pt-12">

                                <Link to="/get-demo" class="inline-flex items-center justify-center px-8 py-4 text-base font-semibold text-blue-500 transition-all duration-200 border-2 border-blue-500 rounded-md mt-9 hover:bg-blue-500 hover:text-white hover:border-blue-500 focus:bg-gray-900 focus:text-white focus:border-gray-900">
                                    {tech_security_talk}
                                </Link>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            <Footer />
        </>
    )

}

export default SecurityInfo;